import { ethers } from "ethers";
const contractABI = require("../contracts/hayc-abi.json");
const contractAddress = "0x2B97063CD926DfEaDF8d10d9d4De8B44b9B2E7D4";

export const connectWallet = async () => {
  if ((window as any).ethereum) {
    try {
      const addressArray = await (window as any).ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Mint above!",
        address: addressArray[0],
      };
      return obj;
    } catch (err: any) {
      return {
        address: "",
        status: err.message
      };
    }
  } else {
    return {
      address: "",
      status: "You must install Metamask in your browser!"
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if ((window as any).ethereum) {
    try {
      const addressArray = await (window as any).ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Mint above!",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err: any) {
      return {
        address: "",
        status: err.message
      };
    }
  } else {
    return {
      address: "",
      status: "You must install Metamask in your browser."
    };
  }
};

export const mintNFT = async (amount: any) => {
    try {
        const { ethereum } = window as any;

        const provider = new ethers.providers.Web3Provider(
            (window as any).ethereum,
            "any"
          );
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const contract = await new ethers.Contract(contractAddress, contractABI, signer);
          
          const ethAmount = parseInt(amount) * 0.02;
          let nftTxn = await contract.mint(amount, { value: ethers.utils.parseEther(ethAmount.toString()) });
          await nftTxn.wait();
      
          return { 
              success: true,
              status: 'Mined, see transaction: https://rinkeby.etherscan.io/tx/' + nftTxn.hash};

    } catch (err) {
        console.log(err);
        return {
            success: false,
            status: "Something went wrong, please try again!"
        }
    }   
};

export const getTotalSupply = async () => {
  try {
      const { ethereum } = window as any;

      const provider = new ethers.providers.Web3Provider(
          (window as any).ethereum,
          "any"
        );
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = await new ethers.Contract(contractAddress, contractABI, signer);
        
        let val = await contract.totalSupply();
    
        return parseInt(ethers.utils.formatUnits(val, 0));
  } catch (err) {
      console.log(err);
      return 0;
  }   
};

export const getUserBalance = async (walletAddress: string) => {
  console.log('calling getUserBalance with walletAddress =  ', walletAddress);
  try {
      const { ethereum } = window as any;

      const provider = new ethers.providers.Web3Provider(
          (window as any).ethereum,
          "any"
        );
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = await new ethers.Contract(contractAddress, contractABI, signer);
        
        let val = await contract.balanceOf(walletAddress);
    
        return parseInt(ethers.utils.formatUnits(val, 0));
  } catch (err) {
      console.log(err);
      return 0;
  }   
};