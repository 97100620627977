import React from 'react';
import logo from './logo.svg';
import './App.css';
import Minter from "./components/Minter";
import Header from "./components/Header";
import discord from './assets/svgs/socials/discord.svg';
import twitter from './assets/svgs/socials/twitter.svg';

function App() {
  return (
    <div className="App">
      <a href="https://discord.gg/b3yrKnBWAq" target="_blank" rel="noreferrer">
					<img src={discord} />
				</a>
				<a href="https://twitter.com/HexApeYC" target="_blank" rel="noreferrer">
					<img src={twitter}/>
				</a>
      <Minter></Minter>
    </div>
  );
}

export default App;
