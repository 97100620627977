import { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  getTotalSupply,
  getUserBalance
} from "../utils/interact";
import Background from "../assets/background.jpeg";
import Pics from "../assets/pics.png";

const Minter = (props: any) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const [name, setName] = useState("");
  const [amount, setAmount] = useState("1");
  const [totalSupply, setTotalSupply] = useState(0);
  const [userBalance, setUserBalance] = useState(0);

  useEffect(() => {
      async function run() {
        const {address, status} = await getCurrentWalletConnected();
        setWallet(address);
        setStatus(status);
      }
    addWalletListener();
  }, []);

  function addWalletListener() {
    if ((window as any).ethereum) {
      (window as any).ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("Account connected, mint above! ");
        } else {
          setWallet("");
          setStatus("Please connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
            "You must install Metamask in your browser!"
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    console.log('got walletResponse..');
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    const currTotalSupply = await getTotalSupply();
    console.log('this is walletAddress: ', walletResponse.address)
    const currUserBalance = await getUserBalance(walletResponse.address);
    setTotalSupply(currTotalSupply);
    setUserBalance(currUserBalance);
  };

  const onMintPressed = async () => {
    console.log("onMintPressed, amount is: ", amount);
    const { success, status } = await mintNFT(amount);
    setStatus(status);
  };

  return (
    <div className="Minter">
      <br></br>
      <br></br>
      <br></br>
      
    <img src={Pics} alt="Background" className="responsive" />
  
      <h1 id="title"> Welcome to Hex Ape Yacht Club!</h1>
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Mint is open - connect wallet</span>
        )}
      </button>
      {walletAddress ? <h2> Total: {totalSupply} / 10000 already minted! Your balance: {userBalance} </h2> : <h2>Connect wallet to see how many mints left!</h2>}
      <form>
        <h2>Amount to mint (0.02 ETH per mint, max 10 per wallet): </h2>
        <input
          type="text"
          placeholder="1"
          onChange={(event) => setAmount(event.target.value)}
        />
      </form>
      <button id="mintButton" onClick={onMintPressed}>
        Mint HAYC NFT
      </button>
      <p id="status" style={{ color: "red" }}>
        {status}
      </p>
    
    </div>

    

    
  );
};

export default Minter;